body {
  font-family: $Regular, "Arial";
  &::-webkit-scrollbar {
    width: 6px;
    background-color: $purple;
    &-thumb {
      background-color: $blue;
      border-radius: 5px;
    }
  }
}

.c {
  &-main {
    text-decoration: aqua !important;
  }
}

.container {
  background-color: rgba($color: $white, $alpha: 0.95);
}

.react-horizontal-scrolling-menu--scroll-container {
  &::-webkit-scrollbar {
    height: 8px;
    background-color: $gray;
    &-thumb {
      background-color: $blue;
      border-radius: 5px;
    }
  }
}

.fc {
  &-scroller {
    &::-webkit-scrollbar {
      width: 10px;
      background-color: $gray;
      &-thumb {
        background-color: $blue;
        border-radius: 5px;
      }
    }
  }
}

.modal {
  &-content{
    border: 0px solid;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $gray;
    &-thumb {
      background-color: $blue;
      border-radius: 5px;
    }
  }
}

// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn {
  &-brand {
    &:not(:only-child) {
      .c-icon {
        margin-top: 0 !important;
      }
    }
  }
  &:hover {
    transform: scale(1.08);
  }
  &-primary{
    color: $white;
  }
  &-light:hover {
    background-color: $light;
  }
  &-purple {
    background-color: $purple;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &-blue {
    background-color: $blue;
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.bg {
  &-none {
    background-color: rgba($color: #000000, $alpha: 0);
  }
  &-purple {
    background-color: $purple;
  }
  &-imagen {
    background-image: url("../assets/bg-saisv.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  &-imagendark {
    background-image: url("../assets/bg-saisv-dark.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  &-orange {
    background-color: $orange !important;
    border-color: $danger;
    border-width: 1px;
  }
  &-primary {
    background-color: $primary !important;
    border-color: $dark;
    border-width: 1px;
  }
  &-secondary {
    background-color: $secondary !important;
    border-color: $dark;
    border-width: 1px;
  }
  &-gray{
    background-color: $gray !important;
    border-color: $dark;
    border-width: 1px;
  }
  &-blue{
    background-color: $blue !important;
    border-color: $dark;
    border-width: 1px;
  }
  &-gradient {
    &-primary {
      background: $primary !important;
      background: $linear-gradient-primary !important;
    }
    &-danger {
      background: $danger !important;
      background: $linear-gradient-danger !important;
    }
    &-success {
      background: $success !important;
      background: $linear-gradient-success !important;
    }
    &-color {
      background: $color !important;
      background: $linear-gradient-color !important;
    }
    &-blue {
      background: $blue !important;
      background: $linear-gradient-blue !important;
    }
    &-info {
      background: $info !important;
      background: $linear-gradient-info !important;
    }
    &-dark {
      background: $dark !important;
      background: $linear-gradient-dark !important;
    }
    &-accompaniments {
      background: $blue !important;
      background: $linear-gradient-accompaniments !important;
    }
    &-follow {
      background: $blue !important;
      background: $linear-gradient-follow !important;
    }
    &-modal {
      background: $dark !important;
      background: $linear-gradient-modal !important;
    }
  }
}

.font {
  &-black {
    font-family: $Black;
  }
  &-regular {
    font-family: $Regular;
  }
  &-bold {
    font-family: $Bold;
  }
  &-semi-bold {
    font-family: $SemiBold;
  }
  &-medium {
    font-family: $Medium;
  }
  &-extra-light {
    font-family: $ExtraLight;
  }
}

.text {
  &-purple {
    color: $purple;
  }
  &-color {
    color: $color;
  }
  &-light {
    color: $light;
  }
  &-orange {
    color: $orange;
  }
  &-blue {
    color: $blue;
  }
  &-gray {
    color: $gray;
  }
}

.z-index {
  &-front {
    z-index: 1 !important;
  }
  &-back {
    z-index: 0 !important;
  }
}
